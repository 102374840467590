import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react'
import { IApiError } from '@/types/api'

interface IGeneralContext {
  isLoading: boolean
  fetchData: (
    fetchFunction: (...props: any) => any
  ) => <T = any>(props: T) => Promise<any>
  error?: string
  setError: (value: string) => void
  setIsLoading: (value: boolean) => void // TODO remove after delete old Translation version
  info?: string
  setInfo: (value: string) => void
  success?: string
  setSuccess: (value: string) => void
  resetAllMessages: VoidFunction
}

const GeneralContext = createContext<IGeneralContext>({} as IGeneralContext)

interface IGeneralProviderProps {
  children: ReactNode
}
export const GeneralProvider: FC<IGeneralProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [info, setInfo] = useState<string>()
  const [success, setSuccess] = useState<string>()

  const fetchData =
    (fetchFunction: (...funcProps: any) => any) =>
      async (...props: any) => {
        try {
          setIsLoading(true)
          const data = await fetchFunction(...props)
          if (data.status === 201) {
            setSuccess(data.data.message)
          }
          return data.data
        } catch (err: unknown) {
          if (err instanceof Error && err.message === 'Network Error') {
            throw new Error('Invalid Credentials')
          }
          const { response, message } = err as IApiError
          if (response && response.data) {
            setError(response.data.message)
            throw new Error(response.data.message)
          } else {
            throw new Error(message)
          }
        } finally {
          setIsLoading(false)
        }
      }

  const resetAllMessages = () => {
    setError(undefined)
    setInfo(undefined)
    setSuccess(undefined)
  }

  const value: IGeneralContext = useMemo(
    () => ({
      error,
      fetchData,
      info,
      isLoading,
      setError,
      setInfo,
      setIsLoading, // TODO remove after delete old Translation version
      success,
      setSuccess,
      resetAllMessages
    }),
    [isLoading, error, info, success]
  )
  return (
    <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>
  )
}

export const useGeneral = () => {
  return useContext(GeneralContext)
}
