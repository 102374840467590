import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'
import Endpoints from '../endpoints'
import { ILoginData, TChangePassword } from '@/types/auth'
import { ILoginResponse } from '@/types/api'
import { TSucceedMessage } from '@/types/api'

export const login = async ({ email, password }: ILoginData): Promise<AxiosResponse<ILoginResponse>> => {
  const response = await axiosInstance.post(
    Endpoints.AUTH.LOGIN,
    {
      email,
      password
    },
    {
      withCredentials: true
    }
  )
  return response
}

export const resetPasswordAPI = async (email: string): Promise<AxiosResponse<TSucceedMessage>> => {
  const response = await axiosInstance.post(Endpoints.AUTH.RESET_PASSWORD, { email })

  return response.data
}

export const createPasswordAPI = async ({ passwords, token }: { passwords: TChangePassword, token: string }): Promise<AxiosResponse<TSucceedMessage>> => {
  const response = await axiosInstance.post(`${Endpoints.AUTH.CREATE_PASSWORD}/${token}`, passwords)
  return response.data
}

export const logout = async (): Promise<void> => axiosInstance.post(Endpoints.AUTH.LOGOUT)

export const refreshTokenAPI = async (): Promise<AxiosResponse<ILoginResponse>> => {
  const refreshToken = localStorage.getItem('refreshToken')
  const response = await axiosInstance.post<ILoginResponse>(Endpoints.AUTH.TOKEN_REFRESH, { refreshToken })

  return response
}
