import { useRouteError, useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeft, RefreshCcw } from 'lucide-react'
import './ErrorBoundary.css'

export const ErrorBoundary = () => {
  const error = useRouteError() as { message?: string }
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <section className='h-full flex-center-xy bg-[#F2F2F8]'>
      <div
        className='p-10 relative flex-column items-center gap-3 bg-light-100 rounded-[60px] m-[40px] sm:pt-[80px] sm:px-[60px] sm:pb-[60px] sm:gap-10'>
        <div
          className='absolute right-[-30px] top-[-100px] size-[170px] rounded-full border-[#F2F2F8] border-[6px] bg-[#EDEEF7] p-[20px] sm:top-[-30px] '>
          <img
            className='mix-blend-multiply w-full'
            src='/images/error-mobile.webp'
            alt=''
          />
        </div>
        <h2 className='text-gray-300 text-[120px] font-medium text-center leading-[120px]'>
          Oops..
        </h2>
        <p className='text-gray-300 text-[30px] font-bold text-center leading-8'>Something is going wrong</p>
        <p className='max-w-[570px] text-center text-gray-200 font-medium'>
          Error: {error?.message as string || 'Please check your internet connection and try refreshing the page or go back'}
        </p>
        <div className='flex-center-y gap-2'>
          <button
            onClick={() => navigate(-1)}
            className='px-[20px] py-[10px] flex-center-xy rounded gap-4 bg-primary error-btn'
          >
            <ArrowLeft className='icon' />
            <span>Go Back</span>
          </button>
          <button
            onClick={() => navigate(location.pathname)}
            className='px-[20px] py-[10px] flex-center-xy rounded gap-4 bg-primary error-btn'
          >
            <RefreshCcw className='icon' />
            <span>Reload</span>
          </button>
        </div>
      </div>
    </section>
  )
}
