import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { NON_AUTHORIZED_PAGES, PAGES_SLUGS } from '@/constants/pages'
import LogoIcon from '@/assets/icons/logo.svg'

const Logo = () => {
  const location = useLocation()

  const isLogoDisabled = NON_AUTHORIZED_PAGES.includes(location.pathname)
    ? location.pathname
    : PAGES_SLUGS.jobs

  const isLargeScreen = useMediaQuery({ minWidth: 768 })

  return (
    <Link to={isLogoDisabled} className='flex-center-y gap-2 logo'>
      <LogoIcon />
      {isLargeScreen ? (
        <span className='self-end text-white font-medium leading-[30px]'>
          AVI
        </span>
      ) : null}
    </Link>
  )
}

export default Logo
