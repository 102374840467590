// import { Link } from 'react-router-dom'
// import { PAGES_SLUGS } from '@/constants/pages'
import { X } from 'lucide-react'
import MessageCircleIcon from '@/assets/icons/message/message-circle.svg'
import MessageIcon from '@/assets/icons/message/message.svg'
import MenuIcon from '@/assets/icons/toggle/hamburger-menu.svg'
import SettingIcon from '@/assets/icons/setting.svg'

const Navigation = ({ menu, toggleMenu, isLogin }: { menu: boolean, toggleMenu: () => void, isLogin: boolean }) => {
  return (
    <nav className='w-full flex-space-between'>
      <div className='flex-center-y font-medium'>
        {/*<Link to={PAGES_SLUGS.home} className='px-3 text-white hover:text-primary'>Option</Link>*/}
        {/*<Link to={PAGES_SLUGS.home} className='px-3 text-white hover:text-primary'>Option</Link>*/}
        {/*<Link to={PAGES_SLUGS.home} className='px-3 text-white hover:text-primary'>Option</Link>*/}
      </div>
      {
        !isLogin &&
          <div className='flex-center-y gap-6 md:gap-10'>
            <MessageCircleIcon />
            <MessageIcon />
            <SettingIcon />

            <span onClick={toggleMenu} className='block lg:hidden'>
              { menu ? <X /> : <MenuIcon /> }
            </span>
          </div>
      }
    </nav>
  )
}

export default Navigation
