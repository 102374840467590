import { Toaster } from 'sonner'
import RootLayout from '@/components/layouts/RootLayout'
import { AuthProvider } from '@/context/AuthContext'
import { GeneralProvider } from '@/context/GeneralContext'
import { PageWrapper } from '@/components/layouts/PageWrapper'

const App = () => (
  <GeneralProvider>
    <AuthProvider>
      <PageWrapper>
        <RootLayout />
        <Toaster richColors />
      </PageWrapper>
    </AuthProvider>
  </GeneralProvider>
)

export default App
