import { Suspense, lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PAGES_SLUGS } from '@/constants/pages'
import { ErrorBoundary } from '@/components'

import App from '../App'

const dynamicImport = (importFunction, retries = 3) => {
  return lazy(() =>
    new Promise((resolve, reject) => {
      const attempt = () => {
        importFunction()
          .then(resolve)
          .catch((error) => {
            if (retries === 0) {
              console.error('Failed to load module after multiple attempts:', error)
              reject(error)
            } else {
              setTimeout(() => {
                retries--
                attempt()
              }, 1000) // Retry after 1 second
            }
          })
      }
      attempt()
    })
  )
}

// Lazy loading components
const TrainingsRequests = dynamicImport(() => import('@/pages/trainings-requests/TrainingsRequests'))
const TrainingsTypes = dynamicImport(() => import('@/pages/training-types/TrainingsTypes'))
const DrugAlcohol = dynamicImport(() => import('@/pages/drug-alcohol/DrugAlcohol'))
const Schedule = dynamicImport(() => import('@/pages/schedule/Schedule'))
const ScheduleInfo = dynamicImport(() => import('@/pages/schedule-info/ScheduleInfo'))
const ScheduleVacationRequests = dynamicImport(() => import('@/pages/schedule-vacation/ScheduleVacation.tsx'))
const ScheduleStatistics = dynamicImport(() => import('@/pages/schedule-statistics/ScheduleStatistics'))
const Members = dynamicImport(() => import('@/pages/members/Members'))
const Member = dynamicImport(() => import('@/pages/member/Member'))
const NewMember = dynamicImport(() => import('@/pages/new-member/NewMember'))
const Jobs = dynamicImport(() => import('@/pages/jobs/Jobs'))
const Job = dynamicImport(() => import('@/pages/job/Job'))
const NewJob = dynamicImport(() => import('@/pages/new-job/NewJob'))
const Login = dynamicImport(() => import('@/pages/auth/login/Login'))
const ResetPassword = dynamicImport(() => import('@/pages/auth/reset-password/ResetPassword'))
const CreatePassword = dynamicImport(() => import('@/pages/auth/create-password/CreatePassword'))
const Aircrafts = dynamicImport(() => import('@/pages/aircrafts/Aircrafts'))
const Categories = dynamicImport(() => import('@/pages/categories/Categories'))
const Consumables = dynamicImport(() => import('@/pages/consumables/Consumables'))
const CalibratedTools = dynamicImport(() => import('@/pages/сalibrated-tools/CalibratedTools'))
const Customers = dynamicImport(() => import('@/pages/customers/Customers'))
const Customer = dynamicImport(() => import('@/pages/customer/Customer'))
const NewCustomer = dynamicImport(() => import('@/pages/new-customer/NewCustomer'))
const NotFound = dynamicImport(() => import('@/pages/404/NotFound'))

const Loading = () => <div>Loading...</div>

const SuspenseWrapper = ({ children }) => <Suspense fallback={<Loading />}>{children}</Suspense>


export const router = createBrowserRouter([
  {
    path: PAGES_SLUGS.home,
    element: <Navigate to={PAGES_SLUGS.jobs} replace />,
  },
  {
    path: PAGES_SLUGS.home,
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      // Members
      {
        path: PAGES_SLUGS.members,
        element: <SuspenseWrapper><Members /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.memberId,
        element: <SuspenseWrapper><Member /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.addNewMember,
        element: <SuspenseWrapper><NewMember /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.editMemberId,
        element: <SuspenseWrapper><NewMember /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Jobs
      {
        path: PAGES_SLUGS.jobs,
        element: <SuspenseWrapper><Jobs /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.jobId,
        element: <SuspenseWrapper><Job /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.addNewJob,
        element: <SuspenseWrapper><NewJob /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.editJobId,
        element: <SuspenseWrapper><NewJob /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Consumables
      {
        path: PAGES_SLUGS.consumables,
        element: <SuspenseWrapper><Consumables /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Aircrafts
      {
        path: PAGES_SLUGS.aircrafts,
        element: <SuspenseWrapper><Aircrafts /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Categories
      {
        path: PAGES_SLUGS.categories,
        element: <SuspenseWrapper><Categories /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Calibrated tools
      {
        path: PAGES_SLUGS.calibratedTools,
        element: <SuspenseWrapper><CalibratedTools /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Customers
      {
        path: PAGES_SLUGS.customers,
        element: <SuspenseWrapper><Customers /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.customerId,
        element: <SuspenseWrapper><Customer /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.addNewCustomer,
        element: <SuspenseWrapper><NewCustomer /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.editCustomerId,
        element: <SuspenseWrapper><NewCustomer /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Trainings
      {
        path: PAGES_SLUGS.trainingsRequests,
        element: <SuspenseWrapper><TrainingsRequests /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.trainingsTypes,
        element: <SuspenseWrapper><TrainingsTypes /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // DrugAlcohol
      {
        path: PAGES_SLUGS.drugAlcohol,
        element: <SuspenseWrapper><DrugAlcohol /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Schedule
      {
        path: PAGES_SLUGS.schedule,
        element: <SuspenseWrapper><Schedule /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.scheduleId,
        element: <SuspenseWrapper><ScheduleInfo /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.scheduleVacationRequests,
        element: <SuspenseWrapper><ScheduleVacationRequests /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.scheduleStatistics,
        element: <SuspenseWrapper><ScheduleStatistics /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Auth
      {
        path: PAGES_SLUGS.login,
        element: <SuspenseWrapper><Login /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.acceptInvite,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.createPassword,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.resetPassword,
        element: <SuspenseWrapper><ResetPassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.verifyPassword,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Not-Found
      {
        path: PAGES_SLUGS.notFound,
        element: <SuspenseWrapper><NotFound /></SuspenseWrapper>,
      },
    ]
  }
])
