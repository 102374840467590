import { FC, ReactNode, useEffect, useState } from 'react'

import axiosInstance from '@/lib/instance'

interface ILoaderWrapper {
  children: ReactNode
}

const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const inc = (mod) => setCounter((c) => c + mod)

    const handleRequest = (config) => (inc(1), config)
    const handleResponse = (response) => (inc(-1), response)
    const handleError = (error) => (inc(-1), Promise.reject(error))

    // add request interceptors
    const reqInterceptor = axiosInstance.interceptors.request.use(
      handleRequest,
      handleError
    )
    // add response interceptors
    const resInterceptor = axiosInstance.interceptors.response.use(
      handleResponse,
      handleError
    )
    return () => {
      // remove all intercepts when done
      axiosInstance.interceptors.request.eject(reqInterceptor)
      axiosInstance.interceptors.response.eject(resInterceptor)
    }
  }, [])

  return counter > 0
}
export const LoaderWrapper: FC<ILoaderWrapper> = ({ children }) => {
  const loading = useAxiosLoader()
  const showAppLoader = () => {
    const element = document.querySelector('.app-loader')
    if (element) {
      element.classList.add('in-app-loader')
      element.setAttribute('hidden', 'false')
      element.classList.add('flex')
    }
  }

  const hideAppLoader = () => {
    const element = document.querySelector('.app-loader')
    if (element) {
      element.setAttribute('hidden', 'true')
      element.classList.remove('flex')
    }
  }

  useEffect(() => {
    window.addEventListener('load', hideAppLoader)
  }, [])

  useEffect(() => {
    if (loading) {
      showAppLoader()
    } else {
      hideAppLoader()
    }
  }, [loading])

  return <>{children}</>
}
