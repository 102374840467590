import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { router } from '@/routes/router'
import ReactQueryProvider from '@/providers/ReactQueryProvider'
import '@/styles/globals.css'
import { LoaderWrapper } from './components/layouts/LoaderWrapper'


const Root = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <ReactQueryProvider>
      <LoaderWrapper>
        <RouterProvider router={router} fallbackElement={<div>Loading...</div>} />
      </LoaderWrapper>
    </ReactQueryProvider>
  </Suspense>
)

createRoot(document.getElementById('root')!).render(<Root />)
