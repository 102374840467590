import axios from 'axios'
import Endpoints from './endpoints'

const { VITE_API_URL } = import.meta.env

const axiosInstance = axios.create({
    baseURL: VITE_API_URL,
    responseType: 'json',
    headers: {
        common: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
})

const urlsSkipAuth = [Endpoints.AUTH.LOGIN, Endpoints.AUTH.LOGIN_ADMIN, Endpoints.AUTH.TOKEN_REFRESH, Endpoints.AUTH.RESET_PASSWORD]

axiosInstance.interceptors.request.use((config) => {
    if (config.url && urlsSkipAuth.includes(config.url)) {
        return config
    }

    const myToken = localStorage.getItem('token')

    if (myToken) {
        config.headers.Authorization = `Bearer ${myToken}`
    }

    return config
})

export default axiosInstance
