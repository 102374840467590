import { MouseEvent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { PAGES_SLUGS } from '@/constants/pages'
import { useAuth } from '@/context/AuthContext'
import './Sidebar.css'

const menuItems = [
  // { name: 'Dashboard', path: PAGES_SLUGS.home },
  { name: 'Jobs', path: PAGES_SLUGS.jobs },
  { name: 'Members', path: PAGES_SLUGS.members },
  { name: 'Recurrent Trainings', path: PAGES_SLUGS.trainingsRequests },
  { name: 'Training Types', path: PAGES_SLUGS.trainingsTypes },
  { name: 'D&A Program', path: PAGES_SLUGS.drugAlcohol },
  { name: 'Schedule', path: PAGES_SLUGS.schedule },
  { name: 'Consumables', path: PAGES_SLUGS.consumables },
  { name: 'Calibrated Tools', path: PAGES_SLUGS.calibratedTools },
  { name: 'Aircrafts', path: PAGES_SLUGS.aircrafts },
  { name: 'Job Categories', path: PAGES_SLUGS.categories },
  { name: 'Customers', path: PAGES_SLUGS.customers }
]

const Sidebar = ({ menu }: { menu: boolean }) => {
  const { onLogout } = useAuth()
  const location = useLocation()
  const isTablet = useMediaQuery({ maxWidth: 1024 })
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path)
  }

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault()
    onLogout()
  }

  return (
    <aside
      className={`sidebar absolute lg:relative h-full right-0 lg:block duration-300 ease-in-out text-white z-10
        ${isTablet ? (menu ? 'block w-[350px] bg-primary' : 'hidden') : 'block bg-dark-default'} ${isMobile ? 'fixed z-[100]' : ''}`}
    >
      <nav className='py-[40px] flex-column relative'>
        {menuItems.map(item => (
          <Link
            key={item.name}
            to={item.path}
            className={`sidebar-link relative py-5 px-[40px] leading-[16px]
             ${(item.name === 'Consumables' || item.name === 'Customers') && 'mt-7'}
             ${isLinkActive(item.path) ? 'text-white font-bold' : 'text-gray-100 font-medium'} ${menu && 'text-white hover:bg-white hover:text-primary'} hover:bg-primary hover:text-white duration-300`}
          >
            <span
              className={`absolute left-0 top-0 bottom-0 h-full w-[6px] bg-primary rounded-r duration-300 
            ${isLinkActive(item.path) ? 'block' : 'hidden'}`}
            ></span>
            {item.name}
          </Link>
        ))}
        <Link
          key='logout'
          onClick={handleLogout}
          to=''
          className='sidebar-link absolute bottom-0 text-gray-100 left-0 w-full py-5 px-[40px] leading-[16px] hover:bg-primary hover:text-white duration-300'
        >
          Logout
        </Link>
      </nav>
    </aside>
  )
}

export default Sidebar
