const Endpoints = {
  AUTH: {
    LOGIN_ADMIN: 'login-admin',
    LOGIN: 'login',
    RESET_PASSWORD: 'reset_password',
    CREATE_PASSWORD: 'reset_password/reset',
    TOKEN_REFRESH: 'token/refresh',
    LOGOUT: 'token/invalidate',
  },
  MEMBERS: {
    MEMBERS: 'users',
    MEMBER_ME: 'users/me',
    CREATE_MEMBER: 'users/create',
    INVITE_MEMBER: 'users/invite',
    STATUSES: 'users/statuses',
    ROLES: 'users/roles',
    NAMES: 'users/names',
  },
  JOBS: {
    JOBS: 'jobs',
    STATUSES: 'jobs/statuses'
  },
  TASKS: {
    TASKS: 'tasks',
  },
  CONSUMABLES: {
    CONSUMABLES: 'consumables',
  },
  TESTS: {
    TESTS: 'tests',
  },
  VACATIONS: {
    VACATIONS: 'vacations',
  },
  TRAININGS: {
    TRAININGS: 'trainings'
  },
  TRAININGS_TYPES: {
    TRAINING_TYPE: 'training-types'
  },
  FILES: {
    UPLOAD_FILE: 'files'
  },
  LIBRARY: {
    LIBRARY_AUTH: 'library/authorizations',
    LIBRARY_MANUFACTURERS: 'library/manufacturers',
    CUSTOMERS: 'library/customers',
    TOOLS: 'library/tools',
  },
  CATEGORIES: {
    CATEGORY: 'library/job-category',
  },
  CUSTOMERS: {
    CUSTOMERS: 'customers',
  }
}

export default Endpoints
