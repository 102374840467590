export const useLocalStorage = () => {
  
  const getStorageValue = <T = string,>(fieldName: string) => {
    const value = window.localStorage.getItem(fieldName)
    if (value) {
      return value as T
    }
    return value
  }

  const setStorageValue = (fieldName: string, value: string) => {
    window.localStorage.setItem(fieldName, value)
  }

  const removeStorageValue = (fieldName: string) => {
    window.localStorage.removeItem(fieldName)
  }

  return { getStorageValue, setStorageValue, removeStorageValue }
}
